import React, { useEffect, useState } from "react";
import { MissingPointForm } from "../../components/parts/forms";
import { SectionTitle } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import styles from "./style.module.scss";
import { advGetApiHandler } from "../../api/commonApiCall";
import { GET_API_URLS } from "../../utils/apiConstant";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import successGif from "../../assets/successGif.gif";
import { ArrowBarLeft } from "react-bootstrap-icons";

const MissingPoints = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cards, setCards] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("tokens");
    if (token) {
      async function cardFetcher() {
        setLoader(true);
        const cardData = await advGetApiHandler(GET_API_URLS.GET_CARDS);
        cardData &&
          cardData.response &&
          !cardData.response.error &&
          setCards(cardData.response);
        setLoader(false);
      }
      cardFetcher();
    } else {
      navigate(routes.LOGIN);
    }
  }, [navigate]);
  return (
    <div className={`${styles.becomeDealerPage} sectionContainer`}>
      <div className={styles.becomeDealerInner}>
        <SectionTitle>Missing Point Form</SectionTitle>
        {success ? (
          <div className={styles.successBox}>
            <img src={successGif} alt="Success" />
            <h3>Your Missing Points request is submitted successfully.</h3>
            <a href={routes.CANCO_CASH_LOYALTY}>
              <ArrowBarLeft /> Back To Canco Cash Loyalty
            </a>
          </div>
        ) : (
          <MissingPointForm
            loader={(val) => setLoader(val)}
            cards={cards}
            onSuccess={(val) => setSuccess(val)}
          />
        )}

        {loader && (
          <div className={styles.loaderBox}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MissingPoints;
