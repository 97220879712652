// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtC7IsDH7-M8Bqi25ly6asm5NUv3alHA0",
  authDomain: "canco-319211.firebaseapp.com",
  projectId: "canco-319211",
  storageBucket: "canco-319211.appspot.com",
  messagingSenderId: "122624893286",
  appId: "1:122624893286:web:c434f5ef782fcda23028a7",
  measurementId: "G-K49HMC2D47",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
export const storage = getStorage(app);

// Optionally export the app for other Firebase services
export default app;
