import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase/firebaseConfig";
import { ProgressBar } from "react-bootstrap";

const FirebaseUploadFile = ({ name, onBlur, onChange }) => {
  const [uploadName, setUploadName] = useState("Upload Receipt*");
  const [uploadProgress, setUploadProgress] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadName(file.name);

    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload progress: ", progress);
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading file:", error);
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        // formik.setFieldValue("uploadReceipt", downloadUrl);
        onChange(downloadUrl);
      }
    );
  };
  return (
    <>
      <div className="uploadLogoContainer uploadVendorFile">
        <div className="uploadContainer">
          <input
            type="file"
            name={name}
            onChange={handleFileChange}
            onBlur={onBlur}
          />

          <label htmlFor="actual-btn">UPLOAD</label>
          <span id={`fileChosen choosenFile`}>{uploadName}</span>
        </div>
      </div>
      <ProgressBar now={uploadProgress} style={{ width: "100%" }} />
    </>
  );
};

export default FirebaseUploadFile;
