import React from "react";
import successGif from "../../assets/successGif.gif";
import styles from "./style.module.scss";
import { routes } from "../../utils/routes";
import { ArrowBarLeft } from "react-bootstrap-icons";

const ThankYou = () => {
  return (
    <div className={`${styles.becomeDealerPage} sectionContainer`}>
      <div className={styles.becomeDealerInner}>
        <div className={styles.successBox}>
          <img src={successGif} alt="Success" />
          <h3>Your profile is updated successfully.</h3>
          <a href={routes.EDIT_USER_PROFILE}>
            <ArrowBarLeft /> Back To Edit Profile
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
