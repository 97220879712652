import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  ErrorText,
} from "../../../styledComponent";
import { realtorApplicationInitialValues } from "../../../../utils/formik/initialvalue";
import { realtorFormValidationSchema } from "../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../utils/helpers";
import styles from "./style.module.scss";
import { UploadFile } from "../../../ui";
import { BASE_URL, POST_API_URLS } from "../../../../utils/apiConstant";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const BecomeRealtorForm = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: realtorApplicationInitialValues,
    validationSchema: realtorFormValidationSchema,
    onSubmit: (values) => formSubmitHandler(values),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  async function formSubmitHandler(data) {
    props.loader(true);

    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }
    try {
      const apiResponse = await axios.post(
        `${BASE_URL + POST_API_URLS.REALTOR_FORM}`,
        formData
      );
      props.loader(false);
      apiResponse && apiResponse.data && props.success(true);
    } catch (error) {
      console.log("error", error?.response);
      props.loader(false);
      toast.error(
        error?.response?.data?.message || "Error while submitting Form",
        {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        }
      );
    }
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className={styles.becomeRealtorForm}>
        <div>
          <div>
            <Label htmlFor="first_name">First Name*</Label>
            <Input
              type="text"
              id="first_name"
              {...formik.getFieldProps("first_name")}
              style={
                formik.touched.first_name &&
                formik.errors.first_name && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <ErrorText className={styles.errorText}>
                {formik.errors.first_name}
              </ErrorText>
            )}
          </div>
          <div>
            <Label htmlFor="last_name">Last Name*</Label>
            <Input
              type="text"
              id="last_name"
              {...formik.getFieldProps("last_name")}
              style={
                formik.touched.last_name &&
                formik.errors.last_name && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <ErrorText className={styles.errorText}>
                {formik.errors.last_name}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="company_name">Company*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("company_name")}
              style={
                formik.touched.company_name &&
                formik.errors.company_name && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.company_name && formik.errors.company_name && (
              <ErrorText className={styles.errorText}>
                {formik.errors.company_name}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="email">Email*</Label>
            <Input
              type="email"
              id="email"
              {...formik.getFieldProps("email")}
              style={
                formik.touched.email &&
                formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
              }
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorText className={styles.errorText}>
                {formik.errors.email}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="phone">Phone Number*</Label>
            <Input
              type="text"
              name="phone"
              id="phone"
              placeholder="(xxx) xxx-xxxx"
              onBlur={formik.handleBlur("phone")}
              onChange={(e) => {
                setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
              }}
              value={phoneNumber}
              style={
                formik.touched.phone &&
                formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
              }
            />
            {formik.touched.phone && formik.errors.phone && (
              <ErrorText className={styles.errorText}>
                {formik.errors.phone}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="offering_lease_or_purchase">
              Are you offering a lease or purchase?*
            </Label>
            <Input
              type="text"
              {...formik.getFieldProps("offering_lease_or_purchase")}
              style={
                formik.touched.offering_lease_or_purchase &&
                formik.errors.offering_lease_or_purchase && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.offering_lease_or_purchase &&
              formik.errors.offering_lease_or_purchase && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.offering_lease_or_purchase}
                </ErrorText>
              )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="address">Address*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("address")}
              style={
                formik.touched.address &&
                formik.errors.address && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.address && formik.errors.address && (
              <ErrorText className={styles.errorText}>
                {formik.errors.address}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="size_of_property">Size of property*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("size_of_property")}
              style={
                formik.touched.size_of_property &&
                formik.errors.size_of_property && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.size_of_property &&
              formik.errors.size_of_property && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.size_of_property}
                </ErrorText>
              )}
          </div>
          <div>
            <Label htmlFor="zoning">What zoning does it have?*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("zoning")}
              style={
                formik.touched.zoning &&
                formik.errors.zoning && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.zoning && formik.errors.zoning && (
              <ErrorText className={styles.errorText}>
                {formik.errors.zoning}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <UploadFile
              onChange={(e) =>
                formik.setFieldValue("attachment", e.currentTarget.files[0])
              }
              onBlur={formik.handleBlur}
              value={formik.values.attachment}
            />
            {formik.touched.attachment && formik.errors.attachment && (
              <ErrorText className={styles.errorText}>
                {formik.errors.attachment}
              </ErrorText>
            )}
          </div>
        </div>
        <Button
          $width="100%"
          className={
            !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
          }
        >
          Submit
        </Button>
      </Form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default BecomeRealtorForm;
