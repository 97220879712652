import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Form,
  Input,
  Label,
  Select,
  Textarea,
  ErrorText,
} from "../../../styledComponent";
import styles from "./style.module.scss";
import { dealerApplicationInitialValues } from "../../../../utils/formik/initialvalue";
import { dealerFormValidationSchema } from "../../../../utils/formik/validationSchema";
import { normalizeInput } from "../../../../utils/helpers";
import { BASE_URL, POST_API_URLS } from "../../../../utils/apiConstant";
import axios from "axios";
import { toast } from "react-toastify";

const BecomeDealerForm = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: dealerApplicationInitialValues,
    validationSchema: dealerFormValidationSchema,
    onSubmit: (values) => formSubmitHandler(values),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  async function formSubmitHandler(data) {
    props.loader(true);

    // let formData = new FormData();
    // for (let k in data) {
    //   formData.append(k, data[k]);
    // }
    try {
      const apiResponse = await axios.post(
        `${BASE_URL + POST_API_URLS.DEALER_FORM}`,
        data
      );
      props.loader(false);
      apiResponse && props.success(true);
    } catch (error) {
      console.log("error", error?.response);
      props.loader(false);
      toast.error(
        error?.response?.data?.message || "Error while submitting Form",
        {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        }
      );
    }
  }

  return (
    <Form onSubmit={formik.handleSubmit} className={styles.becomeDealerForm}>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            id="first_name"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            id="last_name"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="email"
            id="email"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="lastName">Phone Number*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur("phone")}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="gas_station_owner_or_operator">
            Are you currently an owner/operator of a gas station?*
          </Label>
          <Select
            id="gas_station_owner_or_operator"
            {...formik.getFieldProps("gas_station_owner_or_operator")}
            style={
              formik.touched.gas_station_owner_or_operator &&
              formik.errors.gas_station_owner_or_operator && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          >
            <option value={"select"}>Select</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
          </Select>
          {formik.touched.gas_station_owner_or_operator &&
            formik.errors.gas_station_owner_or_operator && (
              <ErrorText className={styles.errorText}>
                {formik.errors.gas_station_owner_or_operator}
              </ErrorText>
            )}
        </div>
      </div>
      {formik.values.gas_station_owner_or_operator === "yes" && (
        <div>
          <div>
            <Label htmlFor="address">
              If yes, please include the address of the location?*
            </Label>
            <Input
              type="text"
              name="address"
              id="address"
              {...formik.getFieldProps("address")}
              style={
                formik.touched.address &&
                formik.errors.address && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.address && formik.errors.address && (
              <ErrorText className={styles.errorText}>
                {formik.errors.address}
              </ErrorText>
            )}
          </div>
        </div>
      )}
      <div>
        <div>
          <Label htmlFor="message">Message*</Label>
          <Textarea
            id="message"
            rows={4}
            cols={50}
            {...formik.getFieldProps("message")}
            style={
              formik.touched.message &&
              formik.errors.message && { boxShadow: "0px 0px 0px 2px red" }
            }
          ></Textarea>
          {formik.touched.message && formik.errors.message && (
            <ErrorText className={styles.errorText}>
              {formik.errors.message}
            </ErrorText>
          )}
        </div>
      </div>
      <Button
        $width="100%"
        type="submit"
        className={
          !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
        }
      >
        Submit
      </Button>
    </Form>
  );
};

export default BecomeDealerForm;
