/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { missingPointsValidationSchema } from "../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  Select,
} from "../../../styledComponent";
import styles from "./style.module.scss";
import { POST_API_URLS } from "../../../../utils/apiConstant";
import { handleApiErrors } from "../../../../utils/helpers";
import { missingPointInitialValues } from "../../../../utils/formik/initialvalue";
import apiInterceptor from "../../../../api/apiCall";
import { toast, ToastContainer } from "react-toastify";
import { FirebaseUploadFile } from "../../../ui";
import { useAuth } from "../../../../context/AuthProvider";

const MissingPointForm = (props) => {
  const { userData } = useAuth();

  const formik = useFormik({
    initialValues: missingPointInitialValues,
    enableReinitialize: true,
    validationSchema: missingPointsValidationSchema,
    onSubmit: (values) => {
      console.log("form values", values);
      formSubmitHandler(values);
    },
  });

  async function formSubmitHandler(data) {
    props.loader(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }
    apiInterceptor
      .post(POST_API_URLS.MISSING_POINT_FORM, formData)
      .then((response) => {
        props.loader(false);
        props.onSuccess(true);
      })
      .catch((error) => {
        props.loader(false);
        const errorMessages = handleApiErrors(error);
        // Display each error in a separate snackbar
        errorMessages.forEach(({ field, message }) => {
          const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
          toast.error(formattedMessage, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.4rem", fontWeight: 500 },
          });
        });
      });
  }

  useEffect(() => {
    if (userData) {
      formik.setFieldValue("firstName", userData?.first_name);
      formik.setFieldValue("lastName", userData?.last_name);
      formik.setFieldValue("email", userData?.email);
    }
  }, [userData]);

  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        className={styles.becomeFleetMemberForm}
      >
        <div>
          <div>
            <Label htmlFor="firstName">First Name*</Label>
            <Input
              type="text"
              disabled
              {...formik.getFieldProps("firstName")}
              style={
                formik.touched.firstName &&
                formik.errors.firstName && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <ErrorText className={styles.errorText}>
                {formik.errors.firstName}
              </ErrorText>
            )}
          </div>
          <div>
            <Label htmlFor="lastName">Last Name*</Label>
            <Input
              type="text"
              disabled
              {...formik.getFieldProps("lastName")}
              style={
                formik.touched.lastName &&
                formik.errors.lastName && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <ErrorText className={styles.errorText}>
                {formik.errors.lastName}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <div className={styles.toolTipLabel}>
              <Label htmlFor="email">Email*</Label>
            </div>
            <Input
              type="email"
              disabled
              {...formik.getFieldProps("email")}
              style={
                formik.touched.email &&
                formik.errors.email && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorText className={styles.errorText}>
                {formik.errors.email}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="cardNumber">Your Card Number*</Label>
            <Select
              defaultValue={"select"}
              {...formik.getFieldProps("cardNumber")}
              style={
                formik.touched.cardNumber &&
                formik.errors.cardNumber && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            >
              <option value={"select"}>Select Card*</option>
              {props?.cards &&
                props?.cards.map((val, i) => (
                  <option key={i} value={val.cardnumber}>
                    {val.cardnumber}
                  </option>
                ))}
            </Select>
            {formik.touched.cardNumber && formik.errors.cardNumber && (
              <ErrorText className={styles.errorText}>
                {formik.errors.cardNumber}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <Label htmlFor="transactionNumber">Transaction Number*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("transactionNumber")}
              style={
                formik.touched.transactionNumber &&
                formik.errors.transactionNumber && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.transactionNumber &&
              formik.errors.transactionNumber && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.transactionNumber}
                </ErrorText>
              )}
          </div>
        </div>
        <div>
          <div>
            <FirebaseUploadFile
              name={"uploadReceipt"}
              onBlur={formik.handleBlur("uploadReceipt")}
              onChange={(val) => formik.setFieldValue("uploadReceipt", val)}
            />
            {formik.touched.uploadReceipt && formik.errors.uploadReceipt && (
              <ErrorText className={styles.errorText}>
                {formik.errors.uploadReceipt}
              </ErrorText>
            )}
          </div>
        </div>
        <div className={styles.formBtnContainer}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
};

export default MissingPointForm;
